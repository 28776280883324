import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from "../components/seo";
import useDate from "react-use-date";
import { ClockBad, ClockGood } from "../components/clock.js";
import { SmallClock } from "../components/clock.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="react-use-date" mdxType="SEO" />
    {/* https://alligator.io/gatsbyjs/mdx-in-gatsby/ */}

    <h1>{`react-use-date`}</h1>
    <p>{`Have you ever used `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`new Date()`}</code>{` in React code, but noticed it didn't update?`}</p>
    <ClockBad mdxType="ClockBad" />
    <p>{`Instead, try using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useDate()`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` useDate `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"react-use-date"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Clock`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` date `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useDate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`The current time is: `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toISOString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ClockGood mdxType="ClockGood" />
    <h2>{`Options by Example`}</h2>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`result`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`useDate()`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><SmallClock opts={{}} mdxType="SmallClock" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`useDate({ interval: 'second' })`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><SmallClock opts={{
              interval: 'second'
            }} mdxType="SmallClock" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`useDate({ interval: 'minute' })`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><SmallClock opts={{
              interval: 'minute'
            }} mdxType="SmallClock" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`useDate({ interval: 'hour' })`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><SmallClock opts={{
              interval: 'hour'
            }} mdxType="SmallClock" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`useDate({ interval: 1000 })`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><SmallClock opts={{
              interval: 1000
            }} mdxType="SmallClock" /></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Get It!`}</h2>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`npm install react-use-date`}</code></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/bmalehorn/react-use-date"
        }}>{`GitHub`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      